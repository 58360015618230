<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ item.id ? 'Edit' : 'New' }} dedication<br>
                <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      v-model="item.published"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    ><small class="ms-1">{{ labels['active'] }} </small></label>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to programmes list"
              @click="$router.push({name: 'researcher.administration.dedications.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="loggedUser.roles.includes('super-admin')"
              class="btn btn-icon btn-flat-secondary"
              @click="$store.dispatch('modals/toggleRequestModal', 'Dedication')"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="item" />
            <div class="card">
              <div
                v-b-toggle="`collapseContent`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <b-collapse
                  :id="`collapseContent`"
                  visible
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.type'] }}</label>
                          <v-select
                            v-model="item.type"
                            label="name"
                            :clearable="false"
                            :options="types"
                            :get-option-key="option => option.id"
                            @search="onSearch({ name: $event }, 'insuranceTypes/filter')"
                          />
                        </div>
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.reason'] }}</label>
                          <textarea
                            id=""
                            v-model="item.reason"
                            name=""
                            cols="30"
                            rows="4"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <hr>
                    <h4>Dates of dedication</h4>
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.begin_date'] }}</label>
                          <date-picker
                            v-model="item.begin_date"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.end_date'] }}</label>
                          <date-picker
                            v-model="item.end_date"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.percentage'] }}</label>
                          <input
                            id=""
                            v-model="item.percentage"
                            type="text"
                            class="form-control"
                            placeholder="%"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>

            <IcreaRemarks
              :item="item"
            />

            <AddFile
              :id="6"
              :title="'Support documentation'"
              :type="4"
              :files="item.files_"
            />

            <InternalNotes
              :notes="item.notes"
              @saveNewNote="saveNewNote"
            />

            <AttachedForm
              :type="'Dedication'"
              :model-id="itemId"
            />
          </div>

        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import IcreaRemarks from '@/views/back/partials/components/IcreaRemarks.vue'
import InternalNotes from '@/views/back/partials/components/InternalNotes.vue'
import SearchResearcher from '../../../partials/components/SearchResearcher.vue'
import 'vue2-datepicker/index.css'
import AddFile from '../../../admin/senior-call/components/AddFile.vue'
import AttachedForm from '../../../forms/partials/AttachedForm.vue'

export default {
  components: {
    SearchResearcher,
    BCollapse,
    DatePicker,
    IcreaRemarks,
    AddFile,
    InternalNotes,
    vSelect,
    AttachedForm,
  },
  data() {
    return {
      itemId: this.$route.params.id,
      sending: false,
      errors: '',
    }
  },
  computed: {
    ...mapGetters({
      item: 'dedications/item',
      types: 'dedicationTypes/items',
      labels: 'sectionLabels/itemLabels',
      user: 'users/user',
      attached: 'requestForms/attached',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'administration.dedications')
    if (this.itemId) {
      await this.$store.dispatch('dedications/fetchId', this.itemId)
    } else {
      await this.$store.dispatch('dedications/cleanType')
      Vue.set(this.item, 'user', this.user)
    }

    await this.$store.dispatch('dedicationTypes/fetchActives')
  },
  methods: {
    async save() {
      this.sending = true
      this.item.attached_form = this.attached
      try {
        if (this.itemId) {
          await this.$store.dispatch('dedications/update', { id: this.itemId, data: this.item })
        } else {
          await this.$store.dispatch('dedications/create', this.item)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.item.id) {
        Vue.swal('The dedaication has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'researcher.administration.dedications.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
    saveNewNote(note) {
      this.item.notes.push(note)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
  },

}
</script>
